





































import { apiMarketingModule } from '@/api/marketing'
import { Component, Vue } from 'vue-property-decorator'
import config from '@/config'
@Component
export default class MarketingCenter extends Vue {
    menuList: any = []

    getModule() {
        apiMarketingModule().then(res => {
            this.menuList = []
            res.map((item: any) => {
                return item.list.map((el: any, i: any) => {
                    el.image = config.baseURL + el.image
                    return el
                })
            })

            let giftCard: any = {
                image: 'http://ttd.rzshzx.com/resource/image/adminapi/default/kanjia.png',
                introduce: '礼品卡管理',
                is_open: true,
                name: '礼品卡',
                page_path: '/gift_card/gift_card',
                tips: ''
            }
            this.menuList = res
            this.menuList.splice(1, 1)
            this.menuList[0].list.push(giftCard)
            let tempMenu: any[] = []
            this.menuList[0].list.map((i: any, n: any) => {
                if (i.name == '优惠券' || i.name == '礼品卡') {
                    tempMenu.push(i)
                }
            })
            this.menuList[0].list = tempMenu
            console.log('this.menuList', this.menuList)
        })
    }

    created() {
        this.getModule()
    }
}
